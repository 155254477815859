import Cookies from 'js-cookie'
import { navigate } from 'gatsby'

const host = process.env.BACKEND_HOST
const authCookieName = 'advauth'
const userLocalStorageName = 'currentUser'
const websiteLocalStorageName = 'currentWebsite'
const statusLocalStorageName = 'currentStatus'
const contractCodeLocalStorageName = 'contractCode'
const helpArticlesLocalStorageName = 'helpArticles'

export const login = ({ email, password }) => {
  return window
    .fetch(`${host}/connect/v1/advertiser/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password,
      }),
    })
    .then((response) => response.json())
    .then((json) => {
      const token = json.data.token
      if (token) {
        Cookies.set(authCookieName, token, { expires: 365 })
      }
      return !!token
    })
    .catch((error) => {
      return false
    })
}

export const loginAs = (token) => {
  Cookies.set(authCookieName, token, { expires: 365 })
  navigate('/')
}

export const logout = () => {
  Cookies.remove(authCookieName)
  localStorage.removeItem(userLocalStorageName)
  navigate('/')
}

export const checkLogin = () => {
  const token = Cookies.get(authCookieName)
  return !!token
}

export const authToken = () => {
  return Cookies.get(authCookieName)
}

let currentUserMemo = null

export const setCurrentUser = (user) => {
  currentUserMemo = user
  localStorage.setItem(userLocalStorageName, JSON.stringify(user))
}

export const getCurrentUser = () => {
  if (currentUserMemo) return currentUserMemo
  return currentUserMemo = JSON.parse(localStorage.getItem(userLocalStorageName) || '{}')
}

export const setCurrentWebsite = (website) =>
  localStorage.setItem(websiteLocalStorageName, website)

export const getCurrentWebsite = () =>
  localStorage.getItem(websiteLocalStorageName)

export const setCurrentStatus = (status) =>
  localStorage.setItem(statusLocalStorageName, JSON.stringify(status))

export const getCurrentStatus = () =>
  JSON.parse(localStorage.getItem(statusLocalStorageName) || '{}')

export const setContractCode = (contractCode) =>
  localStorage.setItem(contractCodeLocalStorageName, contractCode)

export const getContractCode = () =>
  localStorage.getItem(contractCodeLocalStorageName)

export const setHelpArticles = (articles) =>
  localStorage.setItem(helpArticlesLocalStorageName, JSON.stringify(articles))

export const getHelpArticles = () =>
  JSON.parse(localStorage.getItem(helpArticlesLocalStorageName) || 'false')
